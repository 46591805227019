<template>
  <Modal
    v-model="visible"
    width="800"
    @on-cancel="returnPath"
    :title="modalTitle">
    <div class="bmap-container">
      <label>关键词：<input v-model="keyword"></label>
      <baidu-map class="bm-view"
        v-if="visible"
        :center="center"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        @ready="readyHandler"
        ak="K1F23gVzribgjDKFLCtSKwm1SGa4VlZN">
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
        <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP', 'BMAP_SATELLITE_MAP']" anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-map-type>
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
        <bm-marker v-if="mode==='single'&&markerPoint"
          :position="markerPoint"
          :title="`${markerPoint.lng},${markerPoint.lat}`"
          :dragging="true"
          @dragstart="markerPointDragstart"
          @dragging="markerPointDragging"
          @dragend="markerPointDragend"
          @click="showMarkerPointInfoWindow=true">
          <bm-info-window
            :show="showMarkerPointInfoWindow"
            @close="showMarkerPointInfoWindow=false"
            @open="showMarkerPointInfoWindow=true">
            <h3>{{address}}</h3>
            <div class="font-grey small">{{markerPoint.lng}},{{markerPoint.lat}}</div>
            <div class="surrounding-positions" v-if="surroundings">
              <div class="padding-top-10" />
              <h4>附近地点</h4>
              <ul class="surrounding-ul">
                <li v-for="(sur,surIdx) in surroundings" :key="surIdx" @click="clickSurroundingPoint(sur)">
                  {{sur.title}}
                </li>
              </ul>
            </div>
          </bm-info-window>
        </bm-marker>
        <template  v-if="mode==='multi'">
          <bm-marker
            v-for="(mp,mpIdx) in markerPoints"
            :key="mpIdx"
            :title="`${mp.lng},${mp.lat}`"
            :icon="{url:'http://api.map.baidu.com/img/markers.png',size:{width:23,height:25},opts:{imageOffset:{width:0,height:-250}}}"
            @rightclick="multiPointRightClick"
            :dragging="true"
            @dragstart="multiPointDragstart"
            @dragend="multiPointDragend"
            :position="mp">
          </bm-marker>
        </template>
        <template  v-if="mode==='view'&&markerPoints&&markerPoints.length">
          <bm-marker
            title="项目所在地"
            :position="markerPoints[0]">
          </bm-marker>
          <bm-marker
            v-for="(mp,mpIdx) in markerPoints.filter((m,idx)=>{return idx>0})"
            :key="mpIdx"
            :title="`${mp.lng},${mp.lat}`"
            :icon="{url:'http://api.map.baidu.com/img/markers.png',size:{width:23,height:25},opts:{imageOffset:{width:0,height:-250}}}"
            :position="mp">
          </bm-marker>
        </template>
        <bm-view class="mpview"></bm-view>
        <bm-local-search
          class="bm-local-search"
          @markersset="localSearchMarkerSet"
          @infohtmlset="localSearchInfoHtmlSet"
          :keyword="keyword" :auto-viewport="true"></bm-local-search>
      </baidu-map>
    </div>
    <div slot="footer">
      <Button class="pull-left" size="large" @click="returnPath">取消</Button>
      <Button type="primary" size="large" class="pull-right" style="margin-left:5px" v-if="mode==='single'&&markerPoint" @click="select">选择</Button>
      <Button type="primary" size="large" class="pull-right" style="margin-left:5px" v-if="mode==='multi'&&(markerPoints instanceof Array)" @click="selectMulti">确定</Button>
      <div style="clear:both"></div>
    </div>
  </Modal>
</template>

<script>
import bmap from './../../utils/bmap'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow.vue'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation.vue'
import BmMapType from 'vue-baidu-map/components/controls/MapType.vue'
import BmCityList from 'vue-baidu-map/components/controls/CityList.vue'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    picked: {
      type: Array
    },
    mode: {
      type: String,
      default: 'single'
    }
  },
  components: {
    BaiduMap,
    BmView,
    BmLocalSearch,
    BmNavigation,
    BmMarker,
    BmInfoWindow,
    BmGeolocation,
    BmMapType,
    BmCityList
  },
  data () {
    return {
      visible: false,
      center: {lng: 104.114129, lat: 37.550339},
      zoom: 5,
      map: null,
      BMap: null,
      address: null,
      surroundings: null,
      markerPoint: null,
      showMarkerPointInfoWindow: false,
      markerPoints: [],
      showMultiPointMenu: false,
      activeMultiPoint: null,
      keyword: null
    }
  },
  watch: {
    'show': function (newVal) {
      if (newVal) {
        if (this.mode === 'single') {
          this.markerPoints = []
        } else {
          this.markerPoint = null
        }
        this.visible = true
      } else {
        this.visible = false
      }
    },
    async picked (newVal) {
      this.initPoints(newVal)
    }
  },
  mounted () {
  },
  computed: {
    modalTitle () {
      let title = ''
      switch (this.mode) {
        case 'single':
          title = '选择坐标点'
          break
        case 'multi':
          title = '选择多个坐标点'
          break
        case 'view':
          title = '查看位置'
          break
        default:
          title = '选择坐标点'
          break
      }
      return title
    }
  },
  methods: {
    async initPoints (points) {
      console.log('init pts', points)
      if (points) {
        if (this.mode === 'single') {
          if (points instanceof Array && points.length === 2) {
            bmap.convertGCJToBD(points[0], points[1]).then(res => {
              this.center.lng = res.x
              this.center.lat = res.y
              this.zoom = 15
              this.markerPoint = {
                lng: res.x,
                lat: res.y
              }
            })
          }
        } else if (this.mode === 'multi') {
          if (points instanceof Array) {
            let tasks = []
            points.forEach((p, pIdx) => {
              tasks.push(
                bmap.convertGCJToBD(p[0], p[1]).then(res => {
                  if (pIdx === 0) {
                    this.center.lng = res.x
                    this.center.lat = res.y
                    this.zoom = 15
                  }
                  this.markerPoints.push({
                    lng: res.x,
                    lat: res.y
                  })
                })
              )
            })
            Promise.all(tasks)
          }
        } else if (this.mode === 'view') {
          if (points instanceof Array) {
            let tasks = []
            points.forEach((p, pIdx) => {
              tasks.push(
                bmap.convertGCJToBD(p[0], p[1]).then(res => {
                  if (pIdx === 0) {
                    this.center.lng = res.x
                    this.center.lat = res.y
                    this.zoom = 15
                  }
                  this.markerPoints.push({
                    lng: res.x,
                    lat: res.y
                  })
                })
              )
            })
            await Promise.all(tasks)
          }
        }
      }
    },
    multiPointRightClick (e) {
      console.log(e)
      let point = e.target.point
      let index = this.markerPoints.findIndex(o => o === point)
      this.markerPoints.splice(index, 1)
      this.activeMultiPoint = null
    },
    readyHandler ({BMap, map}) {
      this.BMap = BMap
      this.map = map
      console.log(BMap, map)
      // this.center = '郑州市'
      // this.zoom = 10
      // this.center.lng = 113.660720
      // this.center.lat = 34.799770
      // this.zoom = 15
      this.map.addEventListener('click', (e) => {
        if (this.mode === 'single') {
          this.showMarkerPointInfoWindow = false
          console.log(e.point)
          this.getLocName(e.point)
          this.markerPoint = e.point
          this.$nextTick(() => {
            this.showMarkerPointInfoWindow = true
          })
        } else if (this.mode === 'multi') {
          console.log(e)
          if (this.markerPoints instanceof Array && this.markerPoints.length >= 10) {
            this.$Modal.warning({
              title: '提示',
              content: '最多选择10个坐标点'
            })
            return false
          }
          this.markerPoints.push(e.point)
        }
      })
    },
    getLocName (point) {
      let geoc = new this.BMap.Geocoder()
      geoc.getLocation(point || this.markerPoint, (rs) => {
        console.log(rs)
        this.address = rs.address
        this.surroundings = rs.surroundingPois
      })
    },
    markerPointDragstart () {
      this.showMarkerPointInfoWindow = true
    },
    markerPointDragging (e) {
      this.showMarkerPointInfoWindow = false
      this.markerPoint = e.point
    },
    markerPointDragend (e) {
      this.getLocName(e.point)
      this.showMarkerPointInfoWindow = true
      this.markerPoint = e.point
    },
    multiPointDragstart (e) {
      this.keyword = null
      console.log(e)
      let point = e.target.JA
      let exPointIdx = this.markerPoints.findIndex(o =>
        o.lng === point.lng && o.lat === point.lat
      )
      console.log(exPointIdx)
      this.activeMultiPoint = exPointIdx
    },
    multiPointDragend (e) {
      console.log(e, this.activeMultiPoint)
      if (this.activeMultiPoint !== null && this.activeMultiPoint >= 0) {
        this.markerPoints[this.activeMultiPoint] = e.point
        this.activeMultiPoint = null
      }
    },
    clickSurroundingPoint (pt) {
      this.keyword = null
      this.markerPoint = pt.point
      this.address = pt.address
      this.surroundings = null
    },
    async select () {
      let pointGCJ
      if (this.markerPoint) {
        await bmap.convertBDToGCJ(this.markerPoint.lng, this.markerPoint.lat).then((res) => {
          pointGCJ = [res.x, res.y]
        })
      }
      this.$emit('select', pointGCJ, this.address)
      this.visible = false
      this.keyword = null
    },
    async selectMulti () {
      let pointGCJs = []
      let tasks = []
      if (this.markerPoints instanceof Array) {
        this.markerPoints.forEach((m) => {
          tasks.push(
            bmap.convertBDToGCJ(m.lng, m.lat).then((res) => {
              pointGCJs.push([res.x, res.y])
            })
          )
        })
        await Promise.all(tasks)
      }
      this.$emit('select-multi', pointGCJs)
      this.visible = false
      this.keyword = null
    },
    returnPath () {
      this.keyword = null
      this.$emit('close')
      this.visible = false
    },
    localSearchMarkerSet (pois) {
      console.log(pois)
    },
    localSearchInfoHtmlSet (poi) {
      console.log(poi)
      this.markerPoint = poi.point
      this.address = poi.address
    }
  }
}
</script>

<style lang="less" scoped>
@import './../../theme/plain.less';
.bm-view {
  width: 100%;
  height: 500px;
  position: relative;
  .mpview {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .bm-local-search {
    position: absolute;
    width: 360px;
    z-index: 50;
    top: 0;
  }
}
.surrounding-ul {
  & > li {
    cursor: pointer;
    &:hover {
      color: @primary-color;
    }
  }
}
</style>
