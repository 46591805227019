/**
 * 火星坐标系（GCJ02）转百度坐标系
 * @param {*} longitudeX 
 * @param {*} latitudeY 
 * @returns 
 */
const convertGCJToBD = (longitudeX, latitudeY) => {
  return new Promise((resolve, reject) => {
    if (!window.BMap || !window.BMap.Convertor) {
      reject(new Error('BaiduMapAPI not loaded'))
    }
    try {
      let cvtor = new window.BMap.Convertor()
      let points = []
      if ((longitudeX instanceof Array && !(latitudeY instanceof Array))
        ||(!(longitudeX instanceof Array) && latitudeY instanceof Array)
        ||(longitudeX instanceof Array && latitudeY instanceof Array && longitudeX.length !== latitudeY)) {
        reject(new Error('输入坐标点数据错误'))
      } else if (longitudeX instanceof Array && latitudeY instanceof Array) {
        longitudeX.forEach((x, idx) => {
          points.push(new window.BMap.Point(x, latitudeY[idx]))
        })
      } else {
        points.push(new window.BMap.Point(longitudeX, latitudeY))
      }
      // https://lbsyun.baidu.com/index.php?title=webapi/guide/changeposition
      // from: 1-GPS（WGS84）,3-火星坐标（GCJ02）,5-BD09
      cvtor.translate(points, 3, 5, (res) => {
        // console.log(res.points[0])
        if (points.length === 1) {
          resolve({
            x: res.points[0].lng,
            y: res.points[0].lat
          })
        } else {
          let result = []
          res.points.forEach((p) => {
            result.push({
              x: p.lng,
              y: p.lat
            })
          })
          resolve(result)
        }
      })
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * GPS坐标系（WGS84）转百度坐标系
 * @param {*} longitudeX 
 * @param {*} latitudeY 
 * @returns 
 */
 const convertWGSToBD = (longitudeX, latitudeY) => {
  return new Promise((resolve, reject) => {
    if (!window.BMap || !window.BMap.Convertor) {
      reject(new Error('BaiduMapAPI not loaded'))
    }
    try {
      let cvtor = new window.BMap.Convertor()
      let points = []
      if ((longitudeX instanceof Array && !(latitudeY instanceof Array))
        ||(!(longitudeX instanceof Array) && latitudeY instanceof Array)
        ||(longitudeX instanceof Array && latitudeY instanceof Array && longitudeX.length !== latitudeY)) {
        reject(new Error('输入坐标点数据错误'))
      } else if (longitudeX instanceof Array && latitudeY instanceof Array) {
        longitudeX.forEach((x, idx) => {
          points.push(new window.BMap.Point(x, latitudeY[idx]))
        })
      } else {
        points.push(new window.BMap.Point(longitudeX, latitudeY))
      }
      // https://lbsyun.baidu.com/index.php?title=webapi/guide/changeposition
      // from: 1-GPS（WGS84）,3-火星坐标（GCJ02）,5-BD09
      cvtor.translate(points, 1, 5, (res) => {
        // console.log(res.points[0])
        if (points.length === 1) {
          resolve({
            x: res.points[0].lng,
            y: res.points[0].lat
          })
        } else {
          let result = []
          res.points.forEach((p) => {
            result.push({
              x: p.lng,
              y: p.lat
            })
          })
          resolve(result)
        }
      })
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * 百度坐标系转GCJ02
 * @param {*} longitudeX 
 * @param {*} latitudeY 
 * @returns 
 */
const convertBDToGCJ = (longitudeX, latitudeY) => {
  return new Promise((resolve, reject) => {
    if (!window.BMap || !window.BMap.Convertor) {
      reject(new Error('BaiduMapAPI not loaded'))
    }
    try {
      let cvtor = new window.BMap.Convertor()
      let points = []
      if ((longitudeX instanceof Array && !(latitudeY instanceof Array))
        ||(!(longitudeX instanceof Array) && latitudeY instanceof Array)
        ||(longitudeX instanceof Array && latitudeY instanceof Array && longitudeX.length !== latitudeY)) {
        reject(new Error('输入坐标点数据错误'))
      } else if (longitudeX instanceof Array && latitudeY instanceof Array) {
        longitudeX.forEach((x, idx) => {
          points.push(new window.BMap.Point(x, latitudeY[idx]))
        })
      } else {
        points.push(new window.BMap.Point(longitudeX, latitudeY))
      }
      // https://lbsyun.baidu.com/index.php?title=webapi/guide/changeposition
      // from: 1-GPS（WGS84）,3-火星坐标（GCJ02）,5-BD09
      cvtor.translate(points, 5, 3, (res) => {
        // console.log(res.points[0])
        if (points.length === 1) {
          resolve({
            x: res.points[0].lng,
            y: res.points[0].lat
          })
        } else {
          let result = []
          res.points.forEach((p) => {
            result.push({
              x: p.lng,
              y: p.lat
            })
          })
          resolve(result)
        }
      })
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * 百度坐标系转WGS84
 * @param {*} longitudeX 
 * @param {*} latitudeY 
 * @returns 
 */
 const convertBDToWGS = (longitudeX, latitudeY) => {
  return new Promise((resolve, reject) => {
    if (!window.BMap || !window.BMap.Convertor) {
      reject(new Error('BaiduMapAPI not loaded'))
    }
    try {
      let cvtor = new window.BMap.Convertor()
      let points = []
      if ((longitudeX instanceof Array && !(latitudeY instanceof Array))
        ||(!(longitudeX instanceof Array) && latitudeY instanceof Array)
        ||(longitudeX instanceof Array && latitudeY instanceof Array && longitudeX.length !== latitudeY)) {
        reject(new Error('输入坐标点数据错误'))
      } else if (longitudeX instanceof Array && latitudeY instanceof Array) {
        longitudeX.forEach((x, idx) => {
          points.push(new window.BMap.Point(x, latitudeY[idx]))
        })
      } else {
        points.push(new window.BMap.Point(longitudeX, latitudeY))
      }
      // https://lbsyun.baidu.com/index.php?title=webapi/guide/changeposition
      // from: 1-GPS（WGS84）,3-火星坐标（GCJ02）,5-BD09
      cvtor.translate(points, 5, 1, (res) => {
        // console.log(res.points[0])
        if (points.length === 1) {
          resolve({
            x: res.points[0].lng,
            y: res.points[0].lat
          })
        } else {
          let result = []
          res.points.forEach((p) => {
            result.push({
              x: p.lng,
              y: p.lat
            })
          })
          resolve(result)
        }
      })
    } catch (err) {
      reject(err)
    }
  })
}

module.exports = {
  convertBDToGCJ,
  convertBDToWGS,
  convertGCJToBD,
  convertWGSToBD
}
